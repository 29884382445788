import '@fontsource/ibm-plex-mono'
import 'inter-ui/inter.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useRef } from 'react'

import { ToggleThemeProvider } from '~lib/ToggleThemeProvider'

import { AppRouter } from './AppRouter'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
})

export const App = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <QueryClientProvider client={queryClient}>
      <ToggleThemeProvider containerRef={ref}>
        <AppRouter />
        {
          // Allow toast to be in front of modal
          // https://github.com/chakra-ui/chakra-ui/issues/6276
        }
        {<div ref={ref} />}
      </ToggleThemeProvider>
    </QueryClientProvider>
  )
}
