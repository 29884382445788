import { z } from 'zod'
export * from './errors'
export * from './links'
export * from './roles'

export const PUBLIC_KEY = 'public'
export const APIKEY_KEY = 'apikey'
export const PERMISSION_KEY = 'permissions'
export const TARGET_WORKSPACE_PERMISSION_KEY = 'target_workspace_permissions'
export const SHAREABLE_LINK_PERMISSION_KEY = 'shareable_link_permissions'

export enum MediaUploadStatus {
  STARTED = 'started',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export enum FileType {
  PNG_MIME_TYPE = 'image/png',
  JPG_MIME_TYPE = 'image/jpg',
  JPEG_MIME_TYPE = 'image/jpeg',
  GIF_MIME_TYPE = 'image/gif',
  SVG_MIME_TYPE = 'image/svg+xml',
  HEIC_MIME_TYPE = 'image/heic',
  HEIF_MIME_TYPE = 'image/heif',
  MP4_MIME_TYPE = 'video/mp4',
  MOV_MIME_TYPE = 'video/quicktime',
  M4V_MIME_TYPE = 'video/x-m4v',
  AVI_MIME_TYPE = 'video/vnd.avi', // retrieved by checking file's magic header
  AVI_2_MIME_TYPE = 'video/x-msvideo', // retrieved by checking file.type
  XML_MIME_TYPE = 'application/xml',
}

export enum FormatName {
  JPEG_FORMAT_NAME = 'jpeg',
  JPG_FORMAT_NAME = 'jpg',
  PNG_FORMAT_NAME = 'png',
  GIF_FORMAT_NAME = 'gif',
  SVG_FORMAT_NAME = 'svg',
  HEIC_FORMAT_NAME = 'heic',
  HEIF_FORMAT_NAME = 'heif',
  MP4_FORMAT_NAME = 'mp4',
  MOV_FORMAT_NAME = 'mov',
  M4V_FORMAT_NAME = 'm4v',
  AVI_FORMAT_NAME = 'avi',
}

export enum LogFormatName {
  ADDED_USER_NAME = 'added user to workspace',
  REMOVED_USER_NAME = 'removed user from workspace',
  UPLOADED_MEDIA_NAME = 'uploaded media',
  DELETED_MEDIA_NAME = 'deleted media',
  DOWNLOADED_MEDIA_NAME = 'downloaded media',
  COPIED_MEDIA_NAME = 'copied media',
  RESTORED_MEDIA_NAME = 'restored media',
  CREATED_WORKSPACE_NAME = 'created workspace',
  CREATED_SHARING_LINK_NAME = 'created shareable link',
}

export enum WorkspaceLogType {
  WORKSPACE_PERMISSIONS = 'Workspace permissions',
  UPLOADS = 'Uploads',
  COPIED_MEDIA = 'Copied media',
  DOWNLOADS = 'Downloads',
  DELETION = 'Deletion',
  RESTORED_MEDIA = 'Restored media',
  LINKS = 'Links',
}

export const ALLOWED_IMAGE_MIME_TYPES: string[] = [
  FileType.PNG_MIME_TYPE,
  FileType.JPG_MIME_TYPE,
  FileType.JPEG_MIME_TYPE,
  FileType.GIF_MIME_TYPE,
  FileType.SVG_MIME_TYPE,
  FileType.HEIC_MIME_TYPE,
  FileType.HEIF_MIME_TYPE,
]

export const ALLOWED_VIDEO_MIME_TYPES: string[] = [
  FileType.MP4_MIME_TYPE,
  FileType.MOV_MIME_TYPE,
  FileType.M4V_MIME_TYPE,
  FileType.AVI_MIME_TYPE,
  FileType.AVI_2_MIME_TYPE,
]

export const ALLOWED_VIDEO_FORMATS: string[] = [
  FormatName.MP4_FORMAT_NAME,
  FormatName.MOV_FORMAT_NAME,
  FormatName.M4V_FORMAT_NAME,
  FormatName.AVI_FORMAT_NAME,
]

// Image formats which can be sent to AWS Rekognition
export const AWS_IMAGE_FORMATS: string[] = [
  FormatName.JPG_FORMAT_NAME,
  FormatName.JPEG_FORMAT_NAME,
  FormatName.PNG_FORMAT_NAME,
]

// Images that cannot be rendered directly
// Thumbnail can still be generated for these formats
export const UNRENDERABLE_IMAGE_FORMATS: string[] = [
  FormatName.HEIC_FORMAT_NAME,
  FormatName.HEIF_FORMAT_NAME,
]

// Videos that cannot be rendered directly
// Thumbnail can still be generated for these formats
export const UNRENDERABLE_VIDEO_FORMATS: string[] = [FormatName.AVI_FORMAT_NAME]

export const UNRENDERABLE_FORMATS: string[] = [
  ...UNRENDERABLE_IMAGE_FORMATS,
  ...UNRENDERABLE_VIDEO_FORMATS,
]

export const ALLOWED_IMAGE_FORMATS: string[] = [
  ...AWS_IMAGE_FORMATS,
  FormatName.GIF_FORMAT_NAME,
  FormatName.SVG_FORMAT_NAME,
  FormatName.HEIC_FORMAT_NAME,
  FormatName.HEIF_FORMAT_NAME,
]

export const ALLOWED_FILTERED_IMAGE_FORMATS: string[] =
  ALLOWED_IMAGE_FORMATS.filter(
    (format) => format !== (FormatName.JPEG_FORMAT_NAME as string),
  )

// All formats, including containing JPEG, that are allowed to be Uploaded
export const ALLOWED_FORMATS: string[] = [
  ...ALLOWED_IMAGE_FORMATS,
  ...ALLOWED_VIDEO_FORMATS,
]

// For restricting of acceptable files for react-dropzone
export const MIME_TYPE_TO_FORMAT_NAME_MAP: {
  [key in Exclude<FileType, FileType.XML_MIME_TYPE>]: string[]
} = {
  [FileType.PNG_MIME_TYPE]: ['.' + FormatName.PNG_FORMAT_NAME],
  [FileType.JPG_MIME_TYPE]: ['.' + FormatName.JPG_FORMAT_NAME],
  [FileType.JPEG_MIME_TYPE]: ['.' + FormatName.JPEG_FORMAT_NAME],
  [FileType.GIF_MIME_TYPE]: ['.' + FormatName.GIF_FORMAT_NAME],
  [FileType.SVG_MIME_TYPE]: ['.' + FormatName.SVG_FORMAT_NAME],
  [FileType.HEIC_MIME_TYPE]: ['.' + FormatName.HEIC_FORMAT_NAME],
  [FileType.HEIF_MIME_TYPE]: ['.' + FormatName.HEIF_FORMAT_NAME],
  [FileType.MP4_MIME_TYPE]: ['.' + FormatName.MP4_FORMAT_NAME],
  [FileType.MOV_MIME_TYPE]: ['.' + FormatName.MOV_FORMAT_NAME],
  [FileType.M4V_MIME_TYPE]: ['.' + FormatName.M4V_FORMAT_NAME],
  [FileType.AVI_MIME_TYPE]: ['.' + FormatName.AVI_FORMAT_NAME],
  [FileType.AVI_2_MIME_TYPE]: ['.' + FormatName.AVI_FORMAT_NAME],
}

// TODO: fetch allowed formats from format table in DB and map to respective IDs
// All formats that are allowed to be Filtered
// We would have transformed JPEG into JPG, so our DB does not contain jpeg for filtering
export const ALLOWED_FILTER_FORMATS: string[] = [
  ...ALLOWED_FILTERED_IMAGE_FORMATS,
  ...ALLOWED_VIDEO_FORMATS,
]

export enum LinkType {
  INTERNAL = 'internal',
}

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const READABLE_DATETIME_FORMAT = `dd MMM yyyy ppp` // @see https://date-fns.org/docs/format
export const DEFAULT_ALBUM_NAME = 'Default'
export const DEFAULT_WORKSPACE_NAME = 'My workspace'
export const DEFAULT_TIMEZONE = 'Asia/Singapore'

export const MAX_TAG_LENGTH = 64

export const MAX_WORKSPACE_NAME_LENGTH = 48

export const THUMBNAIL_HEIGHT = 480

export const WORKSPACE_PATH_PREFIX = 'workspaces/:workspaceId/albums/:albumId'

export const VIDEO_THUMBNAIL_PLACEHOLDER = '/video-thumbnail-placeholder.gif'
export const PHOTO_THUMBNAIL_PLACEHOLDER = '/photo-thumbnail-placeholder.gif'

export enum PermissionAction {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  MANAGE = 'manage',
}

export type PermissionSubjectType = string

export type PermissionParam = [PermissionAction, PermissionSubjectType]

export enum ContextLocation {
  PARAMS = 'params',
  BODY = 'body',
  QUERY = 'query',
}

export type ContextType = `${ContextLocation}`

export type PermissionObject = {
  permissionParamList: PermissionParam[]
  workspaceContext: ContextType
}

export type TargetWorkspacePermissionObject = {
  sourceWorkspacePermissions: {
    permissions: PermissionParam[]
    workspaceContext?: ContextType
  }
  targetWorkspacePermissions: {
    permissions: PermissionParam[]
  }
}

export const CELEBRITY_FACE_THRESHOLD = 93 // in percentage

export const MAX_UPLOAD_AMOUNT = 100
export const MAX_TOTAL_UPLOAD_SIZE = 10000000000 // 10 GB (in B)
export const MAX_IMAGE_SIZE = 50000000 // 50 MB (in B)
export const AWS_S3_PART_SIZE = 50 * 1024 * 1024 // 50 MiB (in B), maximum allowed by AWS is 5GiB (5 * 1024 * 1024 * 1024 B).
export const AWS_S3_MAX_FILE_SIZE = AWS_S3_PART_SIZE * 10000 //~50 GiB (in B) maximum number of parts is 10,000 max object size is 50 TiB
export const AWS_S3_MAX_DELETE_OBJECTS_NUMBER = 1000 // 1000 max objects cna be deleted at a time
export const PART_UPLOAD_CONCURRENCY_LIMIT = 6 //based on browser limits https://docs.diffusiondata.com/cloud/latest/manual/html/designguide/solution/support/connection_limitations.html
export const VIDEO_THUMBNAIL_GENERATION_TIMEOUT = 10000 // 10s (in ms)
export const MAX_MULTIPLE_DOWNLOAD_SIZE = 3000000000 // 3 GB (in B)
export const EMAIL_MULTIPLE_DOWNLOAD_SIZE = 100000000 // 100 MB (in B)
export const MAGIC_NUMBER_BYTES = 4100 // minimum number of bytes for file-type library

export const SessionUserEmailSchema = z.object({
  user: z.object({
    email: z.string(),
  }),
})

export const PAGE_FETCH_LIMIT = 30 // for gallery view, recycle bin, shareable link view

export const DEFAULT_PINNED_TAGS_CATEGORY_NAME = 'Pinned'

export enum tagViewSortCriteria {
  MOST_ITEMS = 'Most items',
  LEAST_ITEMS = 'Least items',
  ALPHABETICAL_A_Z = 'Alphabetical (A-Z)',
  ALPHABETICAL_Z_A = 'Alphabetical (Z-A)',
}
