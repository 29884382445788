import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const modalhelper = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = modalhelper.definePartsStyle({
  dialog: {
    backgroundColor: 'base.canvas.alt',
    borderRadius: '1.5rem',
  },
  overlay: {
    backdropFilter: 'auto',
    backdropBlur: '2px',
    backdropInvert: '15%',
    background: 'none',
  },
  closeButton: {
    position: 'absolute',
    boxSize: '2.5rem',
    fontSize: '1rem',
    right: '1rem',
    _hover: {
      background: 'button.hover',
      color: 'button.hover-text',
    },
    borderRadius: 'full',
  },
})

export const modalTheme = modalhelper.defineMultiStyleConfig({
  baseStyle,
})
