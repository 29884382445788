import { Button } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

import { useInterval } from '../../../hooks/useInterval'
import { OtpError } from './OtpForm'

export interface ResendOtpButtonProps {
  resendOtpCountdown: number
  onResendOtp: () => Promise<void>
  onOtpSuccess: () => void
  onOtpError: (e: OtpError) => void
}

export const ResendOtpButton = ({
  resendOtpCountdown,
  onResendOtp,
  onOtpSuccess,
  onOtpError,
}: ResendOtpButtonProps): JSX.Element => {
  // The counter
  const [timer, setTimer] = useState(resendOtpCountdown)

  const resendOtpMutation = useMutation(onResendOtp, {
    // On success, restart the timer before this can be called again.
    onSuccess: () => {
      onOtpSuccess()
    },
    onError: (e: OtpError) => {
      onOtpError(e)
    },
    onSettled: () => setTimer(resendOtpCountdown),
  })

  useInterval(
    () => setTimer(timer - 1),
    // Stop interval if timer hits 0.
    timer <= 0 ? null : 1000,
  )

  return (
    <Button
      isDisabled={resendOtpMutation.isLoading || timer > 0}
      type="button"
      variant="ghost"
      onClick={() => resendOtpMutation.mutate()}
    >
      <u style={{ textDecoration: 'none' }}>
        Resend OTP
        {timer > 0 && ` in ${timer}s`}
      </u>
    </Button>
  )
}
