import { defineStyleConfig } from '@chakra-ui/react'

export const kbdTheme = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    textColor: 'brand.secondary.900',
    backgroundColor: 'base.content.inverse',
    borderColor: 'brand.secondary.300',
    marginX: '0.25rem',
  },
})
