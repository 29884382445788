import { extendTheme } from '@chakra-ui/react'
import { ThemeProvider } from '@opengovsg/design-system-react'
import {
  createContext,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useState,
} from 'react'

import { theme } from '~/theme'
import { colors_dark } from '~/theme/generated/colors_dark'
import { colors_light } from '~/theme/generated/colors_light'
import { shadows_dark } from '~/theme/generated/shadows_dark'
import { shadows_light } from '~/theme/generated/shadows_light'
import { USER_THEME } from '~features/gallery/constants'

const ThemeContext = createContext<
  | {
      isDarkMode: boolean
      setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
    }
  | undefined
>(undefined)

interface ToggleThemeProviderProps {
  containerRef: RefObject<HTMLDivElement>
  children: ReactNode
}

export const ToggleThemeProvider = ({
  containerRef,
  children,
}: ToggleThemeProviderProps) => {
  // Get the initial theme based on system preference
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem(USER_THEME)
    if (savedTheme) {
      return savedTheme === 'dark'
    }
    // Fallback to dark mode
    return true
  }

  const [isDarkMode, setIsDarkMode] = useState<boolean>(getInitialTheme)

  // Save the user preference to localStorage whenever the theme changes
  useEffect(() => {
    localStorage.setItem(USER_THEME, isDarkMode ? 'dark' : 'light')
  }, [isDarkMode])

  const mergedTheme = extendTheme(theme, {
    colors: isDarkMode ? colors_dark : colors_light,
    shadows: isDarkMode ? shadows_dark : shadows_light,
  })

  const context = {
    isDarkMode,
    setIsDarkMode,
  }

  return (
    <ThemeContext.Provider value={context}>
      <ThemeProvider
        theme={mergedTheme}
        resetCSS
        toastOptions={{
          portalProps: {
            containerRef: containerRef,
          },
        }}
      >
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useCustomTheme = (): {
  isDarkMode: boolean
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useCustomTheme must be used within a ThemeContext')
  }
  return context
}
