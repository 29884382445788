import { omit } from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { CUSTOM_RUM_EVENTS, useRum } from '~lib/rum'
import { createSelectors } from '~utils/createSelectors'
export type FiltersWithoutTags = Omit<Filters, 'tags'>

export type FilterType = {
  filterKey: string
  label: string
}

// The order here affects the order of filters shown in the FilterModal
export const FILTER_TYPE_ARRAY: FilterType[] = [
  { filterKey: 'uploaders', label: 'uploaders' },
  { filterKey: 'formats', label: 'formats' },
  { filterKey: 'geolocations', label: 'countries' },
  { filterKey: 'capturedAt', label: 'dates' },
]

export type FiltersStore = {
  filters: Filters
  filtersCountWithoutTags: number
  setFilters: (filters: Filters) => void
  clearAllFilters: () => void
  onUpdateFilter: (filters: Partial<Filters>) => void
  onToggleTag: (tag: string) => void
  clearAllTags: () => void

  // For FilterModal
  resetPendingFilters: () => void // for reset of pendingFilters if user exits modal
  pendingFilters: FiltersWithoutTags
  setPendingFilters: (newFilters: Partial<FiltersWithoutTags>) => void
  clearPendingFilters: () => void
}

export const countFiltersWithoutTags = (
  filters: FiltersWithoutTags,
): number => {
  let appliedFiltersCount = 0

  const { formats, geolocations, uploaders, capturedAt } = filters

  // Sum up filters across formats, geolocations and uploaders (without tags)
  if (formats && formats.length > 0) appliedFiltersCount += formats.length
  if (geolocations && geolocations.length > 0)
    appliedFiltersCount += geolocations.length
  if (uploaders && uploaders.length > 0) appliedFiltersCount += uploaders.length

  // As long a date is provided, count it as one filter
  if (capturedAt.startDate) appliedFiltersCount += 1

  return appliedFiltersCount
}

export type CapturedAtFilter = {
  startDate: string | undefined
  endDate: string | undefined
}

// Enforces filter to be string or undefined instead of optional as all filter params should exist
export type Filters = {
  formats: string[] | undefined
  geolocations: string[] | undefined
  uploaders: string[] | undefined
  capturedAt: CapturedAtFilter
  tags: string[] | undefined
}

export const defaultFiltersWithoutTags = {
  formats: undefined,
  geolocations: undefined,
  uploaders: undefined,
  capturedAt: { startDate: undefined, endDate: undefined },
}

export const defaultFilters = {
  ...defaultFiltersWithoutTags,
  tags: undefined,
}

const FiltersStore = create<FiltersStore>()(
  devtools(
    (set, get) => ({
      filters: defaultFilters,
      setFilters: (filters: Filters) =>
        set({
          filters,
          filtersCountWithoutTags: countFiltersWithoutTags(filters),
        }),
      clearAllFilters: () =>
        set({ filters: defaultFilters, filtersCountWithoutTags: 0 }),
      filtersCountWithoutTags: 0,
      onUpdateFilter: (values: Partial<Filters>) => {
        set((state) => ({
          filters: { ...state.filters, ...values },
          filtersCountWithoutTags: countFiltersWithoutTags({
            ...state.filters,
            ...values,
          }),
        }))
      },
      onToggleTag: (tag: string) => {
        const tagSet = new Set(get().filters.tags)
        if (tagSet.has(tag)) {
          tagSet.delete(tag)
        } else {
          tagSet.add(tag)
        }
        get().onUpdateFilter({
          tags: tagSet.size > 0 ? Array.from(tagSet) : undefined,
        })
      },
      clearAllTags: () => {
        get().onUpdateFilter({ tags: undefined })
      },
      resetPendingFilters: () =>
        set((state) => ({ pendingFilters: omit(state.filters, 'tags') })),
      pendingFilters: defaultFiltersWithoutTags,
      filterOptions: { geolocations: [], uploaders: [] },
      setPendingFilters: (newFilters: Partial<FiltersWithoutTags>) =>
        set((state) => ({
          pendingFilters: { ...state.pendingFilters, ...newFilters },
        })),
      clearPendingFilters: () =>
        set({ pendingFilters: defaultFiltersWithoutTags }),
    }),
    { enabled: process.env.NODE_ENV !== 'production' },
  ),
)

export const useGenerateFiltersQuery: () => Filters = () => {
  const filters = useFiltersStore((state) => state.filters)
  return filters
}

export const useAreFiltersApplied: () => boolean = () => {
  const filters = useFiltersStore((state) => state.filters)
  return !(
    filters.tags === undefined &&
    filters.formats === undefined &&
    filters.geolocations === undefined &&
    filters.capturedAt.startDate === undefined &&
    filters.capturedAt.endDate === undefined &&
    filters.uploaders === undefined
  )
}

export const useUpdateFilters = () => {
  const { awsRum } = useRum()
  const onUpdateFilter = useFiltersStore.use.onUpdateFilter()
  const onToggleTag = useFiltersStore.use.onToggleTag()
  const areFiltersApplied = useAreFiltersApplied()

  const triggerStartSearchRum: (newFilters: Partial<Filters>) => void = (
    newFilters,
  ) => {
    const haveNewFilters = Object.values(newFilters).some(
      (filter) => filter !== undefined,
    )
    if (!areFiltersApplied && haveNewFilters) {
      if (!awsRum) console.log(newFilters)
      else
        awsRum?.recordEvent(CUSTOM_RUM_EVENTS.startSearch, {
          filters: newFilters,
        })
    }
  }

  const updateFilters: (filters: Partial<Filters>) => void = (filters) => {
    triggerStartSearchRum(filters)

    return onUpdateFilter(filters)
  }

  const toggleFilterTags: (tag: string) => void = (tag) => {
    triggerStartSearchRum({
      tags: [tag],
    })

    return onToggleTag(tag)
  }

  const applyPendingFilters: () => void = () => {
    const pendingFilters = useFiltersStore.getState().pendingFilters
    triggerStartSearchRum(pendingFilters)

    return onUpdateFilter(pendingFilters)
  }
  return { updateFilters, toggleFilterTags, applyPendingFilters }
}

export const useFiltersStore = createSelectors(FiltersStore)
