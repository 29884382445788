import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import { createContext, ReactNode, useContext, useMemo } from 'react'

import {
  APPLICATION_VERSION,
  RUM_APPLICATION_ID,
  RUM_IDENTITY_POOL_ID,
  RUM_SESSION_SAMPLE_RATE,
} from '../constants/config'

type RumContextProps = {
  awsRum: AwsRum | undefined
}

export const CUSTOM_RUM_EVENTS = {
  fileUploadTime: 'file_upload_time',
  multipleFilesDownloadTime: 'multiple_files_download_time',
  fileCopyTime: 'file_copy_time',
  startSearch: 'start_search',
  enterTagView: 'enter_tag_view',
  useTagViewFilter: 'use_tag_view_filter',
}

const RumContext = createContext<RumContextProps | undefined>(undefined)

export const RumProvider = ({ children }: { children: ReactNode }) => {
  const rum = useProvideRum()

  return <RumContext.Provider value={rum}>{children}</RumContext.Provider>
}

export const useRum = (): RumContextProps => {
  const context = useContext(RumContext)
  if (!context) {
    throw new Error(`useRum must be used within a RumProvider component`)
  }
  return context
}

const useProvideRum = () => {
  // memo to ensure only one instance of AwsRum
  const awsRum: AwsRum | undefined = useMemo(
    () => {
      // do not init rum in dev mode
      if (process.env.REACT_APP_ENV === 'development') return

      const config: AwsRumConfig = {
        sessionSampleRate: RUM_SESSION_SAMPLE_RATE,
        identityPoolId: RUM_IDENTITY_POOL_ID,
        endpoint: 'https://dataplane.rum.ap-southeast-1.amazonaws.com',
        telemetries: ['http', 'errors', 'performance'],
        allowCookies: true,
        enableXRay: false,
      }

      const applicationId = RUM_APPLICATION_ID
      const applicationVersion = APPLICATION_VERSION
      const applicationRegion = 'ap-southeast-1'
      return new AwsRum(
        applicationId,
        applicationVersion,
        applicationRegion,
        config,
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { awsRum }
}
