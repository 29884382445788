import { Spinner, SpinnerProps } from '@chakra-ui/react'
import * as React from 'react'

const BASE_STYLE = {
  speed: '0.8s',
  emptyColor: 'interaction.sub.default',
  color: 'base.content.strong',
}

const LOADER_VARIANTS_STYLES = {
  default: {
    thickness: '3px',
    size: 'sm',
  },
  large: {
    thickness: '8px',
    size: 'xl',
  },
}

interface LoaderProps extends SpinnerProps {
  variant?: 'default' | 'large'
}

export const Loader: React.FC<LoaderProps> = ({
  variant = 'default',
  ...props
}) => {
  const mergedStyles = { ...BASE_STYLE, ...LOADER_VARIANTS_STYLES[variant] }

  return <Spinner {...mergedStyles} {...props} />
}
