import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const checkboxhelper = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = checkboxhelper.definePartsStyle({
  container: {
    outline: 'none',
    _checked: {
      outline: 'none',
    },
    _focus: {
      outline: 'none',
    },
    _focusWithin: {
      outline: 'none',
    },
    borderRadius: '0.5rem',
  },
  icon: {
    color: 'base.canvas.default',
    bg: 'interaction.main.active',
    borderColor: 'interaction.main.active',
    _checked: {
      bg: 'interaction.main.active',
      borderColor: 'interaction.main.active',
    },
  },
  control: {
    borderColor: 'interaction.main.active',
    _checked: {
      bg: 'interaction.main.active',
      borderColor: 'interaction.main.active',
    },
  },
})

export const checkboxTheme = checkboxhelper.defineMultiStyleConfig({
  baseStyle,
})
