import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const commonButtonStyles = {
  border: '0px',
  borderRadius: '0.5rem',
  cursor: 'pointer',
}

const primary = defineStyle({
  ...commonButtonStyles,
  bg: 'button.primary',
  textColor: 'button.primary-text',
  _hover: {
    bg: 'button.hover',
    textColor: 'button.hover-text',
  },
  _active: {
    bg: 'button.secondary',
    textColor: 'button.primary-text',
  },
})

const secondary = defineStyle({
  ...commonButtonStyles,
  bg: 'button.secondary',
  textColor: 'base.content.strong',
  _hover: {
    bg: 'button.hover',
    textColor: 'button.hover-text',
  },
  _active: {
    bg: 'button.primary',
    textColor: 'button.primary-text',
  },
})

const brand = defineStyle({
  ...commonButtonStyles,
  bg: 'interaction.main.default',
  textColor: 'base.content.inverse',
  _hover: {
    bg: 'button.hover',
    textColor: 'button.hover-text',
    _disabled: {
      bg: 'interaction.support.disabled-background',
      textColor: 'interaction.support.disabled',
    },
  },
  _active: {
    bg: 'button.hover-text',
    textColor: 'button.hover',
  },
  _disabled: {
    bg: 'interaction.support.disabled-background',
    textColor: 'interaction.support.disabled',
    cursor: 'not-allowed',
  },
})

const outline = defineStyle({
  ...commonButtonStyles,
  bg: 'utility.ui-clear',
  textColor: 'base.content.strong',
  border: '1px solid', //left this in as it's an outline button
  borderColor: 'button.outline',
  _hover: {
    bg: 'button.hover',
    textColor: 'button.hover-text',
  },
  _active: {
    bg: 'button.primary',
    textColor: 'button.primary-text',
  },
})

const clear = defineStyle({
  ...commonButtonStyles,
  bg: 'utility.ui-clear',
  textColor: 'base.canvas.inverse',
  _hover: {
    bg: 'button.hover',
    textColor: 'button.hover-text',
    _disabled: { textColor: 'interaction.support.disabled-alt', bg: 'none' },
  },
  _active: {
    bg: 'button.primary',
    textColor: 'button.primary-text',
  },
  _disabled: {
    textColor: 'interaction.support.disabled-alt',
    cursor: 'not-allowed',
  },
})

const ghost = defineStyle({
  ...commonButtonStyles,
  bg: 'utility.ui-clear',
  textColor: 'base.canvas.inverse',
  _hover: {
    bg: 'button.primary',
    textColor: 'button.primary-text',
    _disabled: { color: 'interaction.support.disabled-alt', bg: 'none' },
  },
  _disabled: {
    textColor: 'interaction.support.disabled-alt',
    background: 'utility.ui-clear',
    cursor: 'not-allowed',
  },
})

const success = defineStyle({
  ...commonButtonStyles,
  bg: 'interaction.success.default',
  textColor: 'base.content.inverse',
  _hover: {
    bg: 'interaction.success.hover',
    _disabled: { bg: 'interaction.support.disabled-background' },
  },
  _disabled: {
    textColor: 'interaction.support.disabled',
    background: 'interaction.support.disabled-background',
    cursor: 'not-allowed',
  },
})

const critical = defineStyle({
  ...commonButtonStyles,
  bg: 'interaction.critical.default',
  textColor: 'base.content.inverse',
  _hover: {
    bg: 'interaction.critical.hover',
  },
})

export const buttonTheme = defineStyleConfig({
  variants: {
    primary,
    secondary,
    brand,
    outline,
    clear,
    ghost,
    success,
    critical,
  },
})
