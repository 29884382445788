import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const calendarHelper = createMultiStyleConfigHelpers(['dayOfMonth'])

const baseStyle = calendarHelper.definePartsStyle({
  dayOfMonth: {
    _selected: { bg: 'interaction.sub-subtle.hover' },
    _hover: { bg: 'interaction.sub-subtle.active' },
    _disabled: {
      color: 'interaction.support.disabled-content',
    },
  },
})
export const calendarTheme = calendarHelper.defineMultiStyleConfig({
  baseStyle,
})
